import React, { CSSProperties } from 'react';
import ReactDOM from 'react-dom/client';
import App from './components/App';
import './index.css';
import 'semantic-ui-css/semantic.min.css';

import { HashRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary } from './components/ErrorBoundery';




const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);


root.render(
    <React.StrictMode>
        <ErrorBoundary>

            <HashRouter>
                <Routes>
                    <Route path="/" element={<App />}>
                        {/* <Route path="" element={<App />} /> */}
                    </Route>
                </Routes>
            </HashRouter>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log)) 
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
