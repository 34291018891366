import React, { useEffect, useState } from 'react';
import { Container, Dimmer, Header, Loader, Message, Segment, Statistic, Table } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';

// Assume these interfaces are defined as per your project
interface Tournaments {
    id?: string,
    date: Date,
    place: number,
    score: number;
    players: Players[];
}

interface Players {
    nickName: string,
    score: number;
    active?: boolean;
}

interface TournamentDisplay {
    _id: string,
    averageScore: number,
    tournamentsPlayed: number;
    lastScore: number;
}

interface ApiResponse {
    activePlayers: TournamentDisplay[];
    recentTournament: Tournaments;
}

interface SortOptions {
    direction: "ascending" | "descending",
    column: "_id" | "averageScore" | "lastScore" | "tournamentsPlayed";
}

function App() {
    const [tours, setTours] = useState<ApiResponse>();
    const [sorted, setSorted] = useState<SortOptions>({
        direction: 'descending',
        column: 'averageScore'
    });

    const getTournaments = () => {
        console.log("process.env.NODE_ENV", `${process.env.REACT_APP_SERVER_API || ''}/api/getTournaments`);
        axios.get(`${process.env.REACT_APP_SERVER_API || ''}api/getTournaments`).then((res) => {
            ;
            console.log(res.data);
            setTours((res.data as ApiResponse));
        });
    };

    useEffect(() => {
        getTournaments();
    }, []);

    const getSort = (column: SortOptions['column']) => {
        return sorted.column === column && sorted.direction === "ascending" ? "descending" : "ascending";
    };

    const sortData = () => {
        if (!tours) return [];

        const sortedData = [...tours.activePlayers];
        const { column, direction } = sorted;

        sortedData.sort((a, b) => {
            let valueA = (a as any)[column];
            let valueB = (b as any)[column];

            if (direction === "ascending") {
                return valueA > valueB ? 1 : -1;
            } else {
                return valueA < valueB ? 1 : -1;
            }
        });

        return sortedData;
    };

    return (
        <>
            {tours ?
                <Container>
                    <Container textAlign='center' style={{ paddingTop: "1em" }}>
                        <Statistic>
                            <Header as='h3'>Last Battle Date: {moment(tours.recentTournament.date).format("DD/MM/YYYY")}</Header>
                            <Statistic.Value>#{tours.recentTournament.place}</Statistic.Value>
                            <Statistic.Label>{tours.recentTournament.score} Pts</Statistic.Label>
                        </Statistic>
                    </Container>
                    <Message warning>
                        <Header textAlign='center' as='h6'>*Average of last {tours.activePlayers.find(p => p._id === "Trickster")?.tournamentsPlayed} battles.</Header>
                    </Message>
                    <Table sortable celled unstackable striped compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={"1"}>#</Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={sorted.column === '_id' ? sorted.direction : undefined}
                                    onClick={() => setSorted({ column: "_id", direction: getSort("_id") })}
                                >
                                    Player
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={sorted.column === 'averageScore' ? sorted.direction : undefined}
                                    onClick={() => setSorted({ column: "averageScore", direction: getSort("averageScore") })}
                                >
                                    Avg
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={sorted.column === 'lastScore' ? sorted.direction : undefined}
                                    onClick={() => setSorted({ column: "lastScore", direction: getSort("lastScore") })}
                                >
                                    Last
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                    sorted={sorted.column === 'tournamentsPlayed' ? sorted.direction : undefined}
                                    onClick={() => setSorted({ column: "tournamentsPlayed", direction: getSort("tournamentsPlayed") })}
                                >
                                    # Battles
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {sortData().map((t, i) => (
                                <Table.Row key={t._id}>
                                    <Table.Cell>{i + 1}</Table.Cell>
                                    <Table.Cell>{t._id}</Table.Cell>
                                    <Table.Cell>{Math.round(t.averageScore)}</Table.Cell>
                                    <Table.Cell>{t.lastScore}</Table.Cell>
                                    <Table.Cell>{t.tournamentsPlayed}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Container>
                :
                <Segment style={{ height: "100vh" }}>
                    <Dimmer active>
                        <Loader size='massive'>Loading</Loader>
                    </Dimmer>
                </Segment>
            }
        </>
    );
}

export default App;
